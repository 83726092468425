<template>
  <div>
    <!-- 发送测试 -->
    <send-message-by-template
      ref="sendMessageByTemplate"
      @ok="getList"
    />
    <a-card :bordered="false" :style="{ background: 'rgb(190, 200, 200)' }">
      <a-table
        :loading="loading"
        size="small"
        tid="1"
        :columns="columns"
        :data-source="list"
        :pagination="false"
      >
        <span slot="operation" slot-scope="text, record">
          <a @click="$refs.sendMessageByTemplate.handleLoadType(record, msgType)" v-hasPermi="['tenant:message:msgType:sendTest']">
            <a-icon type="shrink" />发送测试
          </a>
        </span>
      </a-table>
    </a-card>
  </div>
</template>

<script>
import { getMsgType } from '@/api/message/msgType'
import SendMessageByTemplate from './SendMessageByTemplate'

export default {
  name: 'LoadTemplateList',
  props: {
    msgType: {
      type: Object,
      required: true
    }
  },
  components: {
    SendMessageByTemplate
  },
  data() {
    return {
      list: [],
      // 高级搜索 展开/关闭
      advanced: false,
      loading: false,
      total: 0,
      columns: [
        {
          title: '消息组',
          dataIndex: 'group.groupName',
          align: 'center'
        },
        {
          title: '应用',
          dataIndex: 'app.appName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '消息模板',
          dataIndex: 'template.templateName',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '15%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ]
    }
  },
  filters: {},
  created() {
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    getList() {
      this.loading = true
      getMsgType(this.msgType.id).then(response => {
        if (response.typeChannelConfigs) {
          this.list = response.typeChannelConfigs
          this.total = response.typeChannelConfigs.length
          this.loading = false
        }
      })
    }
  }
}
</script>
