var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("send-message-by-template", {
        ref: "sendMessageByTemplate",
        on: { ok: _vm.getList },
      }),
      _c(
        "a-card",
        {
          style: { background: "rgb(190, 200, 200)" },
          attrs: { bordered: false },
        },
        [
          _c("a-table", {
            attrs: {
              loading: _vm.loading,
              size: "small",
              tid: "1",
              columns: _vm.columns,
              "data-source": _vm.list,
              pagination: false,
            },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function (text, record) {
                  return _c("span", {}, [
                    _c(
                      "a",
                      {
                        directives: [
                          {
                            name: "hasPermi",
                            rawName: "v-hasPermi",
                            value: ["tenant:message:msgType:sendTest"],
                            expression: "['tenant:message:msgType:sendTest']",
                          },
                        ],
                        on: {
                          click: function ($event) {
                            return _vm.$refs.sendMessageByTemplate.handleLoadType(
                              record,
                              _vm.msgType
                            )
                          },
                        },
                      },
                      [
                        _c("a-icon", { attrs: { type: "shrink" } }),
                        _vm._v("发送测试 "),
                      ],
                      1
                    ),
                  ])
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }